import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-staff-on-shift',
  templateUrl: './staff-on-shift.component.html',
})
export class StaffOnShiftComponent implements OnInit {
  shiftInfo: any = {};
  scheduleStaffList = [];
  availableStaffList = [];
  notAvailableStaffList = [];
  shiftId;
  isAll;
  fileUrl = environment.fileUrl;
  isLoding = false;
  isShow = false;
  isEvent;
  eventName;
  fromDate;
  toDate;
  timeFormat;
  dateFormat;
  clientId: any = '';
  locationId: any = '';
  subLocationId: any = '';
  domainInfo: any = {};
  public loading = false;
  public index: number;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.shiftId = this.route.snapshot.params.id;
    this.index = this.route.snapshot.params.index;
    this.isAll = this.route.snapshot.queryParamMap.get('isAll');
    this.isEvent = this.route.snapshot.queryParamMap.get('isEvent') === 'true';
    this.clientId = this.route.snapshot.queryParamMap.get('clientId')
      ? parseInt(this.route.snapshot.queryParamMap.get('clientId'))
      : '';
    this.locationId = this.route.snapshot.queryParamMap.get('locationId')
      ? parseInt(this.route.snapshot.queryParamMap.get('locationId'))
      : '';
    this.subLocationId = this.route.snapshot.queryParamMap.get('subLocationId')
      ? parseInt(this.route.snapshot.queryParamMap.get('subLocationId'))
      : '';
    this.eventName = this.route.snapshot.queryParamMap.get('eventName')
      ? this.route.snapshot.queryParamMap.get('eventName')
      : '';
    this.toDate = this.route.snapshot.queryParamMap.get('toDate')
      ? parseInt(this.route.snapshot.queryParamMap.get('toDate'))
      : '';
    this.fromDate = this.route.snapshot.queryParamMap.get('fromDate')
      ? this.route.snapshot.queryParamMap.get('fromDate')
      : '';
    this.getScheduleStaff();
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    const splittedTime = this.domainInfo.timeformat.split(':');
    if (splittedTime && splittedTime[0] === 'hh') {
      this.timeFormat = '12HoursFormat';
    }
    this.dateFormat =
      this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
  }
  tConvert(time) {
    if (!time) return time;
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  filterStaffList() {
    if (this.scheduleStaffList && this.scheduleStaffList.length > 0) {
      this.availableStaffList = _.filter(this.scheduleStaffList, { available: true });
      this.notAvailableStaffList = _.filter(this.scheduleStaffList, { available: false });
    } else {
      this.availableStaffList = [];
      this.notAvailableStaffList = [];
      this.scheduleStaffList = [];
    }
  }

  getScheduleStaff() {
    this.loading = true;
    const workDate = this.route.snapshot.queryParamMap.get('workDate');
    this.apiService
      .getV2(
        `shift/schedule-staffs/${this.shiftId}?isAll=${this.isAll}&work_date=${workDate}&eventName=${this.eventName}&index=${this.index}`,
      )
      .subscribe(
        (staffList: any) => {
          this.isShow = true;
          if (staffList.status === 200) {
            this.shiftInfo = staffList.shift;
            this.scheduleStaffList = staffList.staffs;
            this.filterStaffList();
          } else {
            this.shiftInfo = {};
            this.scheduleStaffList = [];
            this.filterStaffList();
          }
          this.loading = false;
        },
        () => {
          this.isShow = true;
          this.shiftInfo = {};
          this.scheduleStaffList = [];
          this.filterStaffList();
          this.loading = false;
        },
      );
  }

  shiftAllAction(staffId) {
    this.loading = true;
    const data = {
      shiftId: this.shiftId,
      staff_id: staffId,
      status: 1,
      client_id: this.shiftInfo.client_id,
      index: this.index,
      workDate: this.route.snapshot.queryParamMap.get('workDate'),
    };
    let url;
    if (this.isAll === 'true') {
      url = 'shift/roster-all-shift';
    } else {
      url = 'shift/roster-shift';
    }
    this.isLoding = true;
    this.apiService.postV2(url, data).subscribe(
      (shiftactions: any) => {
        if (shiftactions.status === 200) {
          this.toastr.success(shiftactions.message);
          this.redirectSchedule();
        } else {
          this.isLoding = false;
          this.toastr.success(shiftactions.message);
        }
        this.loading = false;
      },
      (err) => {
        this.errorMessage(err);
      },
    );
  }

  errorMessage(err) {
    this.isLoding = false;
    this.loading = false;
    this.toastr.error(err.message);
  }

  encodeURL(eventName: string) {
    return encodeURIComponent(eventName);
  }

  redirectSchedule(isScheduleRedirection = false) {
    this.shiftInfo = {};
    this.scheduleStaffList = [];
    this.availableStaffList = [];
    this.notAvailableStaffList = [];
    const redirectUrl =
      this.isEvent && !isScheduleRedirection ? `/scheduler-event/${this.encodeURL(this.eventName)}` : '/scheduler';

    const queryParams: any = {
      offset: this.route.snapshot.queryParamMap.get('offset'),
      clientId: this.route.snapshot.queryParamMap.get('clientId'),
      staffId: this.route.snapshot.queryParamMap.get('staffId'),
      top: this.route.snapshot.queryParamMap.get('top'),
      locationId: this.locationId,
      subLocationId: this.subLocationId,
      fromDate: this.fromDate,
      toDate: this.toDate,
      isAll: true,
    };

    if (this.isEvent && !isScheduleRedirection) {
      queryParams.isAll = false;
      queryParams.eventName = this.eventName;
    }

    this.router.navigate([redirectUrl], {
      queryParams,
    });
  }

  shiftUnschedule() {
    this.isLoding = true;
    this.loading = true;
    this.apiService
      .getV2('shift/roster-unschedule/' + this.shiftId, {
        isAll: this.isAll,
        index: this.index,
        workDate: this.route.snapshot.queryParamMap.get('workDate'),
      })
      .subscribe(
        (unschedule: any) => {
          if (unschedule.status === 200) {
            this.toastr.success(unschedule.message);
            this.redirectSchedule();
          } else {
            this.isLoding = false;
            this.toastr.error(unschedule.message);
          }
          this.loading = false;
        },
        (err) => {
          if (err.error) {
            this.errorMessage(err.error);
          } else {
            this.errorMessage(err);
          }
        },
      );
  }
}
