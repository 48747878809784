import * as tslib_1 from "tslib";
import { OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { dateFormats } from '../../constants/dateFormat';
var EventComponent = /** @class */ (function () {
    function EventComponent(apiService, route, router, toastr, modalService, formBuilder) {
        var _this = this;
        this.apiService = apiService;
        this.route = route;
        this.router = router;
        this.toastr = toastr;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.dateSelected = true;
        this.offset = 0;
        this.weekDays = [];
        this.scheduleList = [];
        this.staffList = [];
        this.clientId = '';
        this.locationId = '';
        this.subLocationId = '';
        this.staffId = '';
        this.bsValue = new Date(moment().format('YYYY, MM, DD'));
        this.today = moment().format('YYYY-MM-DD');
        this.permission = {};
        this.showHour = '-1';
        this.interval = 5;
        this.times = [];
        this.tt = 0;
        this.timeList = [];
        this.hourTimeList = [];
        this.miniuteTimeList = [];
        this.hourList = [];
        this.endHourList = [];
        this.minitsList = [];
        this.endMinitsList = [];
        this.loading = false;
        this.submitted = false;
        this.userGroup = localStorage.getItem('userGroup');
        this.userLogId = localStorage.getItem('userLogId');
        this.userId = localStorage.getItem('userid');
        this.weekMomentDays = [];
        this.loader = false;
        this.lastEditDate = moment().add(-10, 'days').format('YYYY-MM-DD');
        this.currentTime = moment().format('HH:mm');
        this.nextDaycurrentTime = moment().toISOString();
        this.top = 0;
        this.shiftInfo = {};
        this.scheduleStaffList = [];
        this.availableStaffList = [];
        this.notAvailableStaffList = [];
        this.domainInfo = {};
        this.dateChanged = true;
        this.maxDate = new Date(moment().add(180, 'days').format('YYYY-MM-DD'));
        this.restrictNextButton = false;
        this.clickDatePicker = false;
        this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
        var date = moment().format('YYYY, MM, DD');
        this.minDate = new Date(date);
        this.bsValue = new Date(moment().format('YYYY, MM, DD'));
        this.jumpDateFormat = dateFormats[this.domainInfo.dateformat];
        var _loop_1 = function (i) {
            var hh = Math.floor(this_1.tt / 60);
            var index = this_1.hourList.findIndex(function (hour) { return hour === ('0' + (hh % 24)).slice(-2); });
            if (index === -1) {
                this_1.hourList.push(('0' + (hh % 24)).slice(-2));
                this_1.endHourList.push(('0' + (hh % 24)).slice(-2));
                this_1.hourTimeList.push(('0' + (hh % 24)).slice(-2));
            }
            var mm = this_1.tt % 60;
            var indexm = this_1.minitsList.findIndex(function (minits) { return minits === ('0' + mm).slice(-2); });
            if (indexm === -1) {
                this_1.minitsList.push(('0' + mm).slice(-2));
                this_1.endMinitsList.push(('0' + mm).slice(-2));
                this_1.miniuteTimeList.push(('0' + mm).slice(-2));
            }
            // this.timeList.push(('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2));
            this_1.tt = this_1.tt + this_1.interval;
        };
        var this_1 = this;
        for (var i = 0; this.tt < 24 * 60; i++) {
            _loop_1(i);
        }
        this.minitsList.push('59');
        this.endMinitsList.push('59');
        this.miniuteTimeList.push('59');
        this.route.queryParams.subscribe(function (params) {
            _this.startEventDate = params.fromDate;
            _this.endEventDate = params.toDate;
        });
        this.eventName = this.route.snapshot.queryParamMap.get('eventName')
            ? this.route.snapshot.queryParamMap.get('eventName')
            : '';
        this.fromDate = this.route.snapshot.queryParamMap.get('fromDate')
            ? this.route.snapshot.queryParamMap.get('fromDate')
            : '';
        this.top = this.route.snapshot.queryParamMap.get('top')
            ? parseInt(this.route.snapshot.queryParamMap.get('top'))
            : 0;
        this.offset = this.route.snapshot.queryParamMap.get('offset')
            ? parseInt(this.route.snapshot.queryParamMap.get('offset'))
            : 0;
    }
    EventComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.today = moment().format('YYYY-MM-DD');
        this.lastEditDate = moment().add(-10, 'days').format('YYYY-MM-DD');
        this.currentTime = moment().format('HH:mm');
        this.nextDaycurrentTime = moment().toISOString();
        this.formInitialize();
        this.getStaffList();
        this.userId = parseInt(this.userId);
        this.clientId = this.route.snapshot.queryParamMap.get('clientId')
            ? parseInt(this.route.snapshot.queryParamMap.get('clientId'))
            : '';
        this.locationId = this.route.snapshot.queryParamMap.get('locationId')
            ? parseInt(this.route.snapshot.queryParamMap.get('locationId'))
            : '';
        this.subLocationId = this.route.snapshot.queryParamMap.get('subLocationId')
            ? parseInt(this.route.snapshot.queryParamMap.get('subLocationId'))
            : '';
        this.staffId =
            this.route.snapshot.queryParamMap.get('staffId') === 'null'
                ? 'null'
                : this.route.snapshot.queryParamMap.get('staffId')
                    ? parseInt(this.route.snapshot.queryParamMap.get('staffId'))
                    : '';
        if (this.userGroup === 'Client') {
            this.clientId = this.userId;
        }
        if (this.userGroup === 'Staff') {
            this.staffId = this.userId;
        }
        this.apiService.getShiftMessage('update_shift').subscribe(function (data) {
            if (data['data'] === true) {
                _this.getEventSchedule(false);
            }
        });
        this.apiService.getProfileChanges('profile:').subscribe(function (data) {
            if (data && data['staff_id'] && data['group']) {
                _this.userGroup = data['group'];
                localStorage.setItem('userGroup', data['group']);
                _this.getEventSchedule(false);
            }
        });
        this.cancelSub = this.apiService.schedule.subscribe(function (res) {
            if (res) {
                _this.getEventSchedule(false);
                _this.apiService.reloadSchedule(null);
            }
        });
        var splittedTime;
        if (this.domainInfo.timeformat !== null) {
            splittedTime = this.domainInfo.timeformat.split(':');
        }
        else {
            this.timeFormat = '24HoursFormat';
        }
        if (splittedTime && splittedTime[0] === 'hh') {
            this.timeFormat = '12HoursFormat';
        }
        this.getWeekDays('New');
    };
    EventComponent.prototype.formInitialize = function () {
        this.shiftForm = this.formBuilder.group({
            shiftId: [''],
            startHr: ['00', [Validators.required]],
            endHr: ['00', [Validators.required]],
            startMin: ['00', [Validators.required]],
            endMin: ['00', [Validators.required]],
            brief: [''],
            code: [''],
            additional_notes: [''],
            workDate: [''],
            index: [''],
        });
    };
    EventComponent.prototype.tConvert = function (time) {
        if (!time)
            return time;
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
            time = time.slice(1);
            time[5] = +time[0] < 12 ? 'AM' : 'PM';
            time[0] = +time[0] % 12 || 12;
        }
        return time.join('');
    };
    EventComponent.prototype.validateEndHour = function () {
        var startHour = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
        var endHour = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
        var resultedStartHour = moment(startHour, 'h:mm');
        var resultedEndHour = moment(endHour, 'h:mm');
        if (resultedEndHour.isBefore(resultedStartHour)) {
            this.shiftForm.get('endHr').setErrors({ endHrerrors: true });
        }
        else {
            this.shiftForm.get('endHr').clearValidators();
        }
    };
    Object.defineProperty(EventComponent.prototype, "f", {
        get: function () {
            return this.shiftForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    EventComponent.prototype.setPageScroll = function () {
        var doc = document.scrollingElement || document.body;
        doc.scrollTop = this.top;
    };
    EventComponent.prototype.checkTimeandDate = function () {
        this.showHour = '-1';
        var fromTime = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
        var toTime = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
        var startHrTime = fromTime.split(':')[0];
        var endHrTime = toTime.split(':')[0];
        var startMinTime = fromTime.split(':')[1];
        var endMinTime = toTime.split(':')[1];
        if (fromTime === toTime || startHrTime > endHrTime || (startHrTime === endHrTime && startMinTime > endMinTime)) {
            this.shiftForm.get('endHr').setErrors({ toTimeError: true });
        }
        else {
            this.shiftForm.get('endHr').clearValidators();
        }
    };
    EventComponent.prototype.selectStartTime = function (value) {
        var index = this.hourList.findIndex(function (obj) { return obj === value; });
        var endTime = this.shiftForm.get('endHr').value;
        var minvalue = this.shiftForm.get('startMin').value;
        var data = [];
        var minData = [];
        this.endHourList = [];
        for (var i = index; i <= this.hourTimeList.length; i++) {
            data.push(this.hourTimeList[i]);
        }
        this.endHourList = data;
        if (endTime <= value) {
            this.endMinitsList = [];
            var minindex = this.minitsList.findIndex(function (obj) { return obj === minvalue; });
            for (var i = minindex; i <= this.miniuteTimeList.length; i++) {
                minData.push(this.miniuteTimeList[i]);
            }
            this.endMinitsList = minData;
            this.shiftForm.patchValue({ endHr: value, endMin: minvalue });
        }
        else {
            this.endMinitsList = this.miniuteTimeList;
            this.shiftForm.patchValue({ endMin: minvalue, endHr: endTime });
            this.shiftForm.get('endHr').clearValidators();
        }
    };
    EventComponent.prototype.createEndTimeAndMins = function (hr, min) {
        var date = new Date();
        var mindate = new Date();
        var interval = 1;
        var mininterval = 5;
        date.setHours(hr);
        date.setMinutes(min);
        mindate.setHours(hr);
        mindate.setMinutes(min);
        var mindata = [];
        var finalMindata = [];
        var stTime = this.shiftForm.get('startHr').value;
        var endTime = this.shiftForm.get('endHr').value;
        var data = [date.getHours() > 9 ? date.getHours() : '0' + date.getHours()];
        for (var i = 0; i < 23; i++) {
            date.setHours(date.getHours() + interval);
            data.push(date.getHours() > 9 ? date.getHours() : '0' + date.getHours());
        }
        for (var i = 0; i < 12; i++) {
            mindate.setMinutes(mindate.getMinutes() + mininterval);
            if (i != 11) {
                mindata.push(mindate.getMinutes());
            }
            else {
                mindata.push(mindata[mindata.length - 1] + 4);
            }
        }
        if (stTime === endTime) {
            mindata.forEach(function (item) {
                item = item > 9 ? item : '0' + item;
                finalMindata.push(item);
            });
        }
        else {
            finalMindata = this.minitsList;
        }
        // this.endHourList = data;
        // this.endMinitsList = finalMindata;
    };
    EventComponent.prototype.selectStartMin = function (value) {
        var minvalue = this.shiftForm.get('endMin').value;
        this.createEndTimeAndMins(value, minvalue);
    };
    EventComponent.prototype.selectendHr = function (value) {
        var stMin = this.shiftForm.get('startMin').value;
        this.selectStartMin(stMin);
        // this.validateEndHour();
    };
    EventComponent.prototype.openEditShiftModal = function (template, shift, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.editShift = shift;
                        this.editShift['isAll'] = type;
                        return [4 /*yield*/, this.setShiftform(shift)];
                    case 1:
                        _a.sent();
                        this.modalRef = this.modalService.show(template, {
                            backdrop: 'static',
                            keyboard: false,
                            class: 'modal-lg modal-dialog-centered roaster-modal',
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    EventComponent.prototype.setShiftform = function (shift) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stHr, stMin, etHr, etMin, brief;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        stHr = shift['start_time'].split(':')[0];
                        stMin = shift['start_time'].split(':')[1];
                        etHr = shift['end_time'].split(':')[0];
                        etMin = shift['end_time'].split(':')[1];
                        return [4 /*yield*/, this.getEditShiftDetails(shift)];
                    case 1:
                        brief = _a.sent();
                        this.shiftForm.patchValue({
                            shiftId: shift['id'],
                            startHr: stHr.toString(),
                            endHr: etHr.toString(),
                            startMin: stMin.toString(),
                            endMin: etMin.toString(),
                            code: shift['code'],
                            additional_notes: shift['additional_notes'],
                            brief: brief,
                            workDate: shift['work_date'],
                            index: shift['index'],
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    EventComponent.prototype.getWeekDays = function (type) {
        if (['Add', 'Sub'].includes(type)) {
            this.clickDatePicker = false;
        }
        if (this.restrictNextButton && type === 'Add') {
            return;
        }
        this.offset = type === 'New' ? this.offset : type === 'Add' ? this.offset + 1 : this.offset - 1;
        this.startOfWeek = moment().add(this.offset, 'weeks').startOf('isoWeek');
        this.endOfWeek = moment().add(this.offset, 'weeks').endOf('isoWeek');
        this.weekDays = [];
        this.weekMomentDays = [];
        var day = this.startOfWeek;
        var changedDate;
        while (day <= this.endOfWeek) {
            this.weekDays.push(moment(day).format('YYYY-MM-DD'));
            if ((this.weekDays.length === 1 && moment(day).isAfter(moment(this.bsValue))) ||
                (this.weekDays.length === 7 && moment(day).isBefore(moment(this.bsValue)))) {
                changedDate = day;
            }
            this.weekMomentDays.push(moment(day).format('YYYY-MM-DD'));
            day = day.clone().add(1, 'd');
        }
        if (changedDate) {
            this.bsValue = new Date(moment(changedDate).format('YYYY, MM, DD'));
        }
        var sixMonthsFromToday = moment().add('180', 'days');
        if (moment(sixMonthsFromToday).isSameOrAfter(this.startOfWeek) &&
            moment(sixMonthsFromToday).isSameOrBefore(this.endOfWeek)) {
            this.restrictNextButton = true;
        }
        else {
            this.restrictNextButton = false;
        }
        this.startOfWeek = moment(this.startOfWeek).format(this.jumpDateFormat);
        this.endOfWeek = moment(this.endOfWeek).format(this.jumpDateFormat);
        this.getEventSchedule(true);
    };
    EventComponent.prototype.shwWarn = function (dp) {
        this.clickDatePicker = dp;
    };
    EventComponent.prototype.getEditShiftDetails = function (shift) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.getV2('shift/edit-shift-details', { shiftId: shift.id }).toPromise()];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data && data['data']['brief']];
                }
            });
        });
    };
    EventComponent.prototype.onDateSelect = function (e) {
        if (e && this.clickDatePicker) {
            this.bsValue = new Date(moment(e).format('YYYY, MM, DD'));
            var currentWeek = moment().add(0, 'weeks').startOf('isoWeek');
            var selectedWeek = moment(e).add(0, 'weeks').endOf('isoWeek');
            if (e) {
                if (currentWeek > selectedWeek) {
                    selectedWeek = moment(e).add(0, 'weeks').startOf('isoWeek');
                }
                else {
                    selectedWeek = moment(e).add(0, 'weeks').endOf('isoWeek');
                }
            }
            else {
                selectedWeek = moment().add(0, 'weeks').endOf('isoWeek');
            }
            var diff = selectedWeek.diff(currentWeek, 'week');
            this.offset = diff;
            // this.offset = this.route.snapshot.queryParamMap.get('offset')
            //   ? parseInt(this.route.snapshot.queryParamMap.get('offset'), 10)
            //   : diff;
            // this.router.navigate(['/scheduler-event']);
            this.getWeekDays('New');
        }
    };
    EventComponent.prototype.redirectSchedule = function () {
        this.shiftInfo = {};
        this.scheduleStaffList = [];
        this.availableStaffList = [];
        this.notAvailableStaffList = [];
        this.router.navigate(['/scheduler'], {
            queryParams: {
                clientId: this.route.snapshot.queryParamMap.get('clientId'),
                staffId: this.route.snapshot.queryParamMap.get('staffId'),
                top: this.route.snapshot.queryParamMap.get('top'),
            },
        });
    };
    EventComponent.prototype.updateWorkDate = function (shiftInfo) {
        return shiftInfo.data.map(function (shift) {
            shift.work_date = shiftInfo.keyDate;
            shift.permanent = shift.permanent ? shift.permanent : 0;
            if (!shift.status) {
                shift.status = null;
            }
            return shift;
        });
    };
    EventComponent.prototype.getEventScheduleList = function (type) {
        var _this = this;
        this.loader = type;
        var from_date = moment(this.startOfWeek, this.jumpDateFormat).format('YYYY-MM-DD');
        var to_date = moment(this.endOfWeek, this.jumpDateFormat).format('YYYY-MM-DD');
        var current_date = moment().format('YYYY-MM-DD');
        var query = {
            from_date: from_date,
            to_date: to_date,
            eventName: this.eventName,
            current_date: current_date,
        };
        if (this.clientId != '') {
            query['clientId'] = this.clientId;
        }
        if (this.locationId != '') {
            query['locationId'] = this.locationId;
        }
        if (this.staffId != '' && this.staffId != 'null') {
            query['staffId'] = this.staffId;
        }
        if (this.staffId == 'null') {
            query['isUnschedule'] = true;
        }
        if (localStorage.getItem('userType').toString() === '1') {
            query['clientId'] = this.userId;
        }
        this.apiService.getV2('shift/schedule-list', query).subscribe(function (data) {
            if (data['status'] === 200) {
                _this.scheduleList = data['schedule'];
                _this.scheduleList.forEach(function (clientSchedule) {
                    clientSchedule &&
                        clientSchedule.data.forEach(function (clientLocation) {
                            clientLocation &&
                                clientLocation.data.forEach(function (shiftInfo) {
                                    shiftInfo.data = _this.updateWorkDate(shiftInfo);
                                });
                        });
                });
            }
            else {
                _this.scheduleList = [];
            }
            _this.loader = false;
            _this.dateSelected = false;
        }, function (err) {
            _this.loader = false;
            _this.dateSelected = false;
        });
    };
    EventComponent.prototype.getEventSchedule = function (type) {
        var _this = this;
        this.loader = type;
        var fromDate = moment(this.startOfWeek, this.jumpDateFormat).format('YYYY-MM-DD');
        var toDate = moment(this.endOfWeek, this.jumpDateFormat).format('YYYY-MM-DD');
        var currentDate = moment().format('YYYY-MM-DD');
        var query = {
            from_date: fromDate,
            to_date: toDate,
            eventName: this.eventName,
            current_date: currentDate,
        };
        if (this.clientId !== '') {
            query['clientId'] = this.clientId;
        }
        if (this.locationId !== '') {
            query['locationId'] = this.locationId;
        }
        if (this.subLocationId !== '') {
            query['subLocationId'] = this.subLocationId;
        }
        if (this.staffId != '' && this.staffId !== 'null') {
            query['staffId'] = this.staffId;
        }
        if (this.staffId === 'null') {
            query['isUnschedule'] = true;
        }
        if (localStorage.getItem('userType').toString() === '1') {
            query['clientId'] = this.userId;
        }
        this.apiService.getV2('shift/schedule-list', query).subscribe(function (data) {
            if (data['status'] === 200) {
                _this.scheduleList = data['schedule'];
                _this.scheduleList.forEach(function (clientSchedule) {
                    clientSchedule &&
                        clientSchedule.data.forEach(function (clientLocation) {
                            clientLocation &&
                                clientLocation.data.forEach(function (shiftInfo) {
                                    shiftInfo.data = _this.updateWorkDate(shiftInfo);
                                });
                        });
                });
                setTimeout(function () {
                    this.setPageScroll();
                }.bind(_this), 1000);
            }
            else {
                _this.scheduleList = [];
            }
            _this.loader = false;
            _this.dateSelected = false;
        }, function (err) {
            _this.loader = false;
            _this.dateSelected = false;
        });
    };
    EventComponent.prototype.handleChange = function () {
        this.getEventSchedule(true);
    };
    EventComponent.prototype.getStaffList = function () {
        var _this = this;
        this.apiService.get('staff/list').subscribe(function (staff) {
            if (staff.status === 200) {
                _this.staffList = staff.staffs;
            }
            else {
                _this.staffList = [];
            }
        });
    };
    EventComponent.prototype.redirectUrl = function (url, shiftId, index, type, workDate, keyDate, endTime) {
        var doc = document.scrollingElement;
        this.top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        var pageUrl = url + shiftId + '/' + index;
        this.router.navigate([pageUrl], {
            queryParams: {
                isAll: type,
                offset: this.offset,
                clientId: this.clientId,
                top: this.top,
                staffId: this.staffId,
                locationId: this.locationId,
                subLocationId: this.subLocationId,
                fromDate: this.startEventDate,
                toDate: this.endEventDate,
                eventName: this.eventName,
                isEvent: true,
                workDate: workDate,
            },
        });
    };
    EventComponent.prototype.shiftAction = function (shift, type) {
        var _this = this;
        var data = { shiftId: shift.id, staff_id: shift.staff_id, index: shift.index, workDate: shift.work_date };
        data['status'] = type === 'Inquire' ? 2 : type === 'Confirm' ? 3 : 4;
        this.apiService.postV2('shift/roster-shift', data).subscribe(function (shiftactions) {
            _this.successMessage(shiftactions);
        });
    };
    EventComponent.prototype.successMessage = function (shiftactions) {
        if (shiftactions['status'] === 200) {
            this.toastr.success(shiftactions['message']);
            this.getEventSchedule(false);
        }
        else {
            this.toastr.success(shiftactions['message']);
        }
    };
    EventComponent.prototype.shiftAllAction = function (shift, type, clientId) {
        var _this = this;
        var data = {
            shiftId: shift.id,
            staff_id: shift.staff_id,
            client_id: clientId,
            index: shift.index,
            workDate: shift.work_date,
        };
        data['status'] = type === 'Inquire' ? 2 : type === 'Confirm' ? 3 : 4;
        this.apiService.postV2('shift/roster-all-shift', data).subscribe(function (shiftactions) {
            _this.successMessage(shiftactions);
        });
    };
    EventComponent.prototype.onSubmit = function () {
        var _this = this;
        this.checkTimeandDate();
        this.validateCurrentDateEndHour();
        this.submitted = true;
        if (this.shiftForm.invalid) {
            return;
        }
        var params = {};
        params.shiftId = this.shiftForm.get('shiftId').value;
        params.brief = this.shiftForm.get('brief').value;
        params.code = this.shiftForm.get('code').value;
        params.additional_notes = this.shiftForm.get('additional_notes').value;
        params.workDate = this.shiftForm.get('workDate').value;
        params.index = this.shiftForm.get('index').value;
        params.start_time = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
        params.end_time = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
        params.isAll = this.editShift['isAll'];
        this.loading = true;
        var apiRequest = this.editShift['isAll'] ? 'shift/edit-all-shifts' : 'shift/edit-shift';
        this.apiService.postV2(apiRequest, params).subscribe(function (shift) {
            _this.loading = false;
            if (shift['status'] === 200) {
                _this.cancelEdit();
                _this.toastr.success(shift['message']);
            }
            else {
                _this.toastr.success(shift['message']);
            }
        }, function (err) {
            _this.toastr.success(err['message']);
            _this.loading = false;
        });
    };
    EventComponent.prototype.cancelEdit = function () {
        this.editShift = {};
        this.modalRef.hide();
        this.getEventSchedule(true);
    };
    EventComponent.prototype.getCurrentWeekschedule = function () {
        this.offset = 0;
        this.getWeekDays('New');
    };
    EventComponent.prototype.getNextShiftDate = function (shift) {
        return moment(shift.start_time, 'HH:mm:ss') < moment(shift.end_time, 'HH:mm:ss')
            ? moment(shift.work_date).format('YYYY-MM-DD')
            : moment(shift.work_date).add(1, 'days').format('YYYY-MM-DD');
    };
    EventComponent.prototype.ngOnDestroy = function () {
        this.cancelSub.unsubscribe();
        this.cancelSub = null;
    };
    EventComponent.prototype.dropdownToggleClick = function () {
        this.currentTime = moment().format('HH:mm');
    };
    EventComponent.prototype.validateCurrentDateEndHour = function () {
        var currentTime = moment().format('HH:mm') + ':00';
        var resultedWorkDate = Date.parse(this.editShift && this.editShift.work_date);
        var workDate = moment(resultedWorkDate).format('YYYY-MM-DD');
        var tDate = moment().format('YYYY-MM-DD');
        var startHour = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
        var endHour = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
        if (workDate === tDate && this.editShift && this.editShift.status === 5) {
            if (!(startHour < currentTime)) {
                this.shiftForm.get('startHr').setErrors({ startHrTimeerrors: true });
            }
            if (!(endHour < currentTime)) {
                this.shiftForm.get('endHr').setErrors({ endHrTimeerrors: true });
            }
        }
        else {
            this.shiftForm.get('endHr').setErrors(null);
            this.shiftForm.get('endHr').clearValidators();
            this.shiftForm.get('startHr').setErrors(null);
            this.shiftForm.get('startHr').clearValidators();
        }
    };
    EventComponent.prototype.checkShiftEndTime = function (keyDate, shift) {
        var currentTime = moment(moment().format('HH:mm:ss'), 'HH:mm:ss');
        var today = moment().format('YYYY-MM-DD');
        var shiftStartTime = moment(shift.start_time, 'HH:mm:ss');
        var shiftEndTime = moment(shift.end_time, 'HH:mm:ss');
        if (moment(shiftEndTime).isBefore(shiftStartTime)) {
            shiftEndTime = shiftEndTime.add(1, 'day');
        }
        return (moment(keyDate).isAfter(moment(today)) ||
            (moment(today).isSame(moment(keyDate)) && moment(shiftEndTime).isSameOrAfter(currentTime)));
    };
    return EventComponent;
}());
export { EventComponent };
