import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
var HttpInterceptorService = /** @class */ (function () {
    function HttpInterceptorService() {
    }
    HttpInterceptorService.prototype.intercept = function (request, next) {
        var token = '';
        localStorage.getItem('token') ? (token = localStorage.getItem('token')) : (token = sessionStorage.getItem('token'));
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'JWT ' + token) });
        }
        var socketId = localStorage.getItem('socketId');
        if (socketId) {
            request = request.clone({ headers: request.headers.set('socketId', window.btoa(socketId)) });
        }
        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        return next.handle(request).pipe(map(function (event) {
            return event;
        }), catchError(function (error) {
            return throwError(error);
        }));
    };
    HttpInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(); }, token: HttpInterceptorService, providedIn: "root" });
    return HttpInterceptorService;
}());
export { HttpInterceptorService };
