import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services';

@Component({
  selector: 'app-scheduler-history',
  templateUrl: './scheduler-history.component.html',
})
export class SchedulerHistoryComponent implements OnInit {
  historyInfo = [];
  shiftId;
  domainInfo: any = {};
  timeFormat;
  dateFormat;
  workDate;
  childShiftId: number;
  public loading = false;
  public index: number;
  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.childShiftId = this.route.snapshot.params.chiildShiftId;
    this.shiftId = this.route.snapshot.params.id;
    this.index = this.route.snapshot.params.index;
    this.workDate = this.route.snapshot.queryParams.workDate;
    this.getTimeTrack();
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    const splittedTime = this.domainInfo.timeformat.split(':');
    if (splittedTime && splittedTime[0] === 'hh') {
      this.timeFormat = '12HoursFormat';
    }
    this.dateFormat =
      this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
  }
  tConvert(time) {
    if (!time) return time;
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }
  getTimeTrack() {
    const params = { index: this.index, work_date: this.workDate };
    this.loading = true;
    this.apiService.getV2('shift/shift-history/' + this.shiftId, params).subscribe(
      (history) => {
        if (history['status'] === 200) {
          this.historyInfo = history['history'];
        } else {
          this.historyInfo = [];
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );
  }

  backSchedule() {
    this.router.navigate(['/scheduler'], {
      queryParams: {
        offset: this.route.snapshot.queryParamMap.get('offset'),
        clientId: this.route.snapshot.queryParamMap.get('clientId'),
        staffId: this.route.snapshot.queryParamMap.get('staffId'),
        top: this.route.snapshot.queryParamMap.get('top'),
      },
    });
  }
}
