import { EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as io from 'socket.io-client';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        var _this = this;
        this.http = http;
        this.subject = new Subject();
        this.shiftModal = new EventEmitter();
        this.searchValue = new EventEmitter();
        this.closeClientModel = new EventEmitter();
        this.headerModel = new EventEmitter();
        this.profileUpdates = new EventEmitter();
        this.profilechangemodal = new EventEmitter();
        this.domainModel = new EventEmitter();
        this.scheduleSubject = new BehaviorSubject(null);
        this.schedule = this.scheduleSubject.asObservable().pipe(distinctUntilChanged());
        this.url = environment.url;
        this.schedulerUrl = environment.schedulerUrl;
        this.organizationId = ['25', '27'];
        this.userId = localStorage.getItem('userid');
        this.userLogId = localStorage.getItem('userLogId');
        this.getShiftMessage = function (event) {
            return _this.socketReturn(event);
        };
        this.getProfileChanges = function (event) {
            return Observable.create(function (observer) {
                _this.socket.on(event + _this.userLogId, function (message) {
                    observer.next(message);
                });
            });
        };
        this.getDashboardChanges = function (event) {
            return _this.socketReturn(event);
        };
        this.reloadSchedule = function (message) {
            _this.scheduleSubject.next(message);
        };
        this.socket = io.connect(environment.socketUrl);
        this.socket.on('connect', function (data) {
            console.info('Socket Connected');
            localStorage.setItem('socketId', window.atob(_this.socket.id));
            _this.socket.emit('register', _this.userLogId);
            console.info('Socket User Registered');
        });
    }
    ApiService.prototype.schedulerList = function () {
        return this.subject.asObservable();
    };
    ApiService.prototype.clearList = function () {
        this.subject.next();
    };
    ApiService.prototype.isAuthenticated = function () {
        if (localStorage.getItem('token')) {
            return true;
        }
        else {
            return false;
        }
    };
    ApiService.prototype.get = function (endpoint, params, reqOpts) {
        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
            };
        }
        if (params) {
            reqOpts.params = new HttpParams();
            for (var k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]); // corrected here
            }
        }
        return this.http.get(this.url + endpoint, reqOpts);
    };
    ApiService.prototype.post = function (endpoint, body, reqOpts) {
        return this.http.post(this.url + '/' + endpoint, body, reqOpts);
    };
    ApiService.prototype.put = function (endpoint, body, reqOpts) {
        return this.http.put(this.url + '/' + endpoint, body, reqOpts);
    };
    ApiService.prototype.delete = function (endpoint, reqOpts) {
        return this.http.delete(this.url + '/' + endpoint, reqOpts);
    };
    ApiService.prototype.patch = function (endpoint, body, reqOpts) {
        return this.http.put(this.url + '/' + endpoint, body, reqOpts);
    };
    ApiService.prototype.getV2 = function (endpoint, params, reqOpts) {
        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
            };
        }
        if (params) {
            reqOpts.params = new HttpParams();
            for (var k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]); // corrected here
            }
        }
        var organizationId = localStorage.getItem('organization_id');
        var url = this.url;
        if (this.organizationId.includes(organizationId)) {
            url = this.schedulerUrl;
        }
        return this.http.get(url + '/' + endpoint, reqOpts);
    };
    ApiService.prototype.postV2 = function (endpoint, body, reqOpts) {
        var organizationId = localStorage.getItem('organization_id');
        var url = this.url;
        if (this.organizationId.includes(organizationId)) {
            url = this.schedulerUrl;
        }
        return this.http.post(url + '/' + endpoint, body, reqOpts);
    };
    ApiService.prototype.putV2 = function (endpoint, body, reqOpts) {
        var organizationId = localStorage.getItem('organization_id');
        var url = this.url;
        if (this.organizationId.includes(organizationId)) {
            url = this.schedulerUrl;
        }
        return this.http.put(url + '/' + endpoint, body, reqOpts);
    };
    ApiService.prototype.deleteV2 = function (endpoint, reqOpts) {
        var organizationId = localStorage.getItem('organization_id');
        var url = this.url;
        if (this.organizationId.includes(organizationId)) {
            url = this.schedulerUrl;
        }
        return this.http.delete(url + '/' + endpoint, reqOpts);
    };
    ApiService.prototype.getReportDetails = function (value) {
        return this.http.post(this.url + "report/download", value);
    };
    ApiService.prototype.filterReportDetails = function (value) {
        return this.http.post(this.url + "report/filter_report", value);
    };
    ApiService.prototype.socketReturn = function (event) {
        var _this = this;
        return Observable.create(function (observer) {
            _this.socket.on(event, function (message) {
                observer.next(message);
            });
        });
    };
    ApiService.prototype.ngOnDestroy = function () {
        this.socket.disconnect();
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
