import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
  selector: 'app-delete-tracking',
  templateUrl: './delete-tracking.component.html',
})
export class DeleteTrackingComponent implements OnInit {
  page = 1;
  pagesize = 25;
  totalCount = 0;
  shiftInfo: any = {};
  shiftList = [];
  isLoading = false;
  public loading = false;
  deleteType: any = false;
  modalRef: BsModalRef;
  public type = false;
  public queryParams: any;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.page = 1;
    this.getDeleteAllShiftInfo();
    if (this.route.snapshot.queryParamMap.get('isAll') && this.route.snapshot.queryParamMap.get('isAll') === 'true') {
      this.deleteType = true;
    } else {
      this.deleteType = false;
    }

    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
  }

  getDeleteAllShiftInfo() {
    this.loading = true;
    const queryParams = { page: this.page, pagesize: this.pagesize };
    const shiftId = this.route.snapshot.params.id;
    const index = this.route.snapshot.params.index;
    const workDate = this.route.snapshot.queryParamMap.get('workDate');
    this.type = this.route.snapshot.queryParamMap.get('isAll') === 'true';
    const query = { shiftId: shiftId, index, isAll: this.type, workDate, ...queryParams };
    this.apiService.getV2('shift/delete-shift-all', query).subscribe(
      (data) => {
        if (data['status'] === 200) {
          this.shiftList = data['shifts'];
          this.totalCount = data['pageDetails'].total;
        } else {
          this.shiftList = [];
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getDeleteAllShiftInfo();
  }

  encodeURL(eventName: string) {
    return encodeURIComponent(eventName);
  }

  cancleDeleteAllShfit() {
    this.shiftInfo = {};
    this.shiftList = [];
    this.isLoading = false;
    if (this.queryParams.isEvent === 'true') {
      if (this.route.snapshot.queryParamMap.get('isAll') === 'true') {
        this.router.navigate([`scheduler`]);
      } else {
        const pageUrl = `scheduler-event/${this.encodeURL(this.queryParams.eventName)}`;
        this.router.navigate([pageUrl], {
          queryParams: {
            eventName: this.queryParams.eventName,
            isAll: this.queryParams.isAll,
            offset: this.queryParams.offset,
            clientId: this.queryParams.clientId,
            locationId: this.queryParams.locationId,
            subLocationId: this.queryParams.subLocationId,
            staffId: this.queryParams.staffId,
            top: this.queryParams.top,
          },
        });
      }
    } else {
      this.router.navigate(['/scheduler'], {
        queryParams: {
          offset: this.queryParams.offset,
          clientId: this.queryParams.clientId,
          staffId: this.queryParams.staffId,
          top: this.queryParams.top,
        },
      });
    }
  }

  deleteAllShift() {
    this.isLoading = true;
    this.loading = true;
    this.type = this.route.snapshot.queryParamMap.get('isAll') === 'true';
    if (this.shiftList && this.shiftList.length > 0) {
      const shiftId = this.route.snapshot.params.id;
      const workDate = this.route.snapshot.queryParamMap.get('workDate');
      const index = this.route.snapshot.params.index;
      const query = { shiftId, deleteAll: this.type, workDate, index };
      this.apiService.postV2('shift/delete', query).subscribe(
        (data) => {
          if (data['status'] === 200) {
            this.toastr.success('Shift Deleted Successfully');
            this.closeModel();
            this.cancleDeleteAllShfit();
          } else {
            this.isLoading = false;
            this.toastr.success(data['message']);
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        },
      );
    }
  }

  openDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }

  closeModel() {
    this.modalRef.hide();
  }
}
