import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { days } from '../constants/defaultValues';

interface ShiftParams {
  client_id: string;
  location_id: string;
  number_of_staffs: number;
  weekdays: number[];
  startDate: string;
  endDate: string;
  sub_location_id?: string;
  event: string;
  code: string;
  additional_notes: string;
  permanent: boolean;
  startTime: string;
  endTime: string;
}

interface ShiftParamsCamelCase {
  clientId: number;
  locationId: number;
  staffCount: number;
  days: number[];
  startDate: string;
  endDate: string;
  subLocationId?: number;
  event: string;
  code: string;
  additionalNotes: string;
  isPermanent: boolean;
  startTime: string;
  endTime: string;
}

@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
})
export class AddShiftComponent implements OnInit {
  bsValue = new Date();
  clientList: any = [];
  clientLocationList: any = [];
  subLocation: any = [];
  weekDays = days;
  interval = 5;
  times = [];
  tt = 0;
  timeList = [];
  hourTimeList = [];
  miniuteTimeList = [];
  hourList = [];
  endHourList = [];
  minitsList = [];
  endMinitsList = [];
  shiftForm: FormGroup;
  submitted = false;
  seletedList: any = [];
  dayNames = '';
  staffList: any = [];
  loading = false;
  top = 0;
  @ViewChild('dp') datepicker: BsDaterangepickerDirective;
  @ViewChild('dp1') datepicker1: BsDaterangepickerDirective;
  minDate: Date;
  maxDate: Date;
  eminDate: Date;
  showHour = '-1';
  isPast = false;
  headerName;
  pastHeader;
  clientId: any = '';
  locationId: any = '';
  subLocationId: any = '';
  eventName: any = '';
  shiftHeaderTitle;
  buttonLabel = 'Add Past Shift';
  setLocation = false;
  scrollx = 0;
  scrolly = 0;
  domainInfo: any = {};
  maxEnddate = new Date(moment().add(180, 'days').format('YYYY-MM-DD'));
  permanent = false;
  endDate;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo')) || {};
    const date = moment().format('YYYY, MM, DD');
    const maxDate = moment().add(180, 'days').format('YYYY, MM, DD');
    this.minDate = new Date(date);
    this.maxDate = new Date(maxDate);
    this.eminDate = new Date(date);
    for (let i = 0; this.tt < 24 * 60; i++) {
      const hh = Math.floor(this.tt / 60);
      const index = this.hourList.findIndex((hour) => hour === ('0' + (hh % 24)).slice(-2));
      if (index === -1) {
        this.hourList.push(('0' + (hh % 24)).slice(-2));
        this.endHourList.push(('0' + (hh % 24)).slice(-2));
        this.hourTimeList.push(('0' + (hh % 24)).slice(-2));
      }
      const mm = this.tt % 60;
      const indexm = this.minitsList.findIndex((minits) => minits === ('0' + mm).slice(-2));
      if (indexm === -1) {
        this.minitsList.push(('0' + mm).slice(-2));
        this.endMinitsList.push(('0' + mm).slice(-2));
        this.miniuteTimeList.push(('0' + mm).slice(-2));
      }

      this.tt = this.tt + this.interval;
    }
    this.minitsList.push('59');
    this.endMinitsList.push('59');
    this.miniuteTimeList.push('59');
  }

  ngOnInit() {
    this.eventName = this.route.snapshot.queryParamMap.get('eventName')
      ? this.route.snapshot.queryParamMap.get('eventName')
      : '';
    this.formInitialize();
    this.getClientList();
    this.getStaffList();
    this.clientId = this.route.snapshot.queryParamMap.get('clientId')
      ? parseInt(this.route.snapshot.queryParamMap.get('clientId'))
      : '';
    this.locationId = this.route.snapshot.queryParamMap.get('locationId')
      ? parseInt(this.route.snapshot.queryParamMap.get('locationId'))
      : '';
    this.subLocationId = this.route.snapshot.queryParamMap.get('subLocationId')
      ? parseInt(this.route.snapshot.queryParamMap.get('subLocationId'))
      : '';

    if (this.clientId !== '') {
      this.shiftForm.get('client_id').setValue(this.clientId);
      this.setLocation = true;
      this.clientChange(this.clientId);
    }

    this.apiService.closeClientModel.subscribe((data) => {
      if (data) {
        this.getClientList();
        this.getStaffList();
      }
    });
  }

  formInitialize() {
    this.shiftForm = this.formBuilder.group({
      client_id: ['', Validators.required],
      location_id: ['', Validators.required],
      number_of_staffs: ['', [Validators.required]],
      weekdays: [[], [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      startHr: ['00', [Validators.required]],
      endHr: ['00', [Validators.required]],
      startMin: ['00', [Validators.required]],
      endMin: ['00', [Validators.required]],
      sub_location_id: [''],
      event: new FormControl({ value: this.eventName, disabled: this.eventName !== '' }),
      code: [''],
      additional_notes: [''],
      permanent: [0],
      staff_id: [''],
      work_date: [''],
    });

    this.shiftForm.get('startDate').valueChanges.subscribe(() => {
      const fromDate = this.shiftForm.get('startDate').value;
      this.eminDate = fromDate;
      this.shiftForm.get('endDate').setValue('');
      this.checkDate();
    });

    this.shiftForm.get('endDate').valueChanges.subscribe(() => {
      this.checkDate();
    });
  }

  buildShiftParams(): ShiftParams {
    const shiftParams: ShiftParams = {
      client_id: this.shiftForm.get('client_id').value,
      location_id: this.shiftForm.get('location_id').value,
      number_of_staffs: this.shiftForm.get('number_of_staffs').value,
      weekdays: this.shiftForm.get('weekdays').value.map(Number),
      startDate: this.getFormattedDate(this.shiftForm.get('startDate').value),
      endDate: this.getFormattedDate(this.shiftForm.get('endDate').value),
      event: this.eventName ? this.eventName : this.shiftForm.get('event').value,
      code: this.shiftForm.get('code').value,
      additional_notes: this.shiftForm.get('additional_notes').value,
      permanent: this.shiftForm.get('permanent').value,
      startTime: `${this.shiftForm.get('startHr').value}:${this.shiftForm.get('startMin').value}:00`,
      endTime: `${this.shiftForm.get('endHr').value}:${this.shiftForm.get('endMin').value}:00`,
    };

    if (this.shiftForm.get('sub_location_id').value) {
      shiftParams.sub_location_id = this.shiftForm.get('sub_location_id').value;
    }

    return shiftParams;
  }

  buildShiftParamsCamelCase(): ShiftParamsCamelCase {
    const shiftParams: ShiftParamsCamelCase = {
      clientId: +this.shiftForm.get('client_id').value,
      locationId: +this.shiftForm.get('location_id').value,
      staffCount: +this.shiftForm.get('number_of_staffs').value,
      days: this.shiftForm.get('weekdays').value.map(Number),
      startDate: this.getFormattedDate(this.shiftForm.get('startDate').value),
      endDate: this.getFormattedDate(this.shiftForm.get('endDate').value),
      event: this.eventName ? this.eventName : this.shiftForm.get('event').value,
      code: this.shiftForm.get('code').value,
      additionalNotes: this.shiftForm.get('additional_notes').value,
      isPermanent: this.shiftForm.get('permanent').value === 1,
      startTime: `${this.shiftForm.get('startHr').value}:${this.shiftForm.get('startMin').value}:00`,
      endTime: `${this.shiftForm.get('endHr').value}:${this.shiftForm.get('endMin').value}:00`,
    };

    if (this.shiftForm.get('sub_location_id').value) {
      shiftParams.subLocationId = +this.shiftForm.get('sub_location_id').value;
    }

    return shiftParams;
  }

  checkTimeandDate() {
    this.showHour = '-1';
    const fromTime = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
    const toTime = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
    this.shiftForm.get('startHr').setErrors(null);
    this.shiftForm.get('startHr').clearValidators();
    if (fromTime === toTime) {
      this.shiftForm.get('endHr').setErrors({ toTimeError: true });
    } else {
      this.shiftForm.get('endHr').setErrors(null);
      this.shiftForm.get('endHr').clearValidators();
    }
  }

  checkDate() {
    let formDate = this.shiftForm.get('startDate').value;
    let toDate = this.shiftForm.get('endDate').value;
    if (formDate) formDate = moment(formDate).add(1, 'days');
    if (toDate) toDate = moment(toDate).add(1, 'days');
    if (formDate && toDate) {
      const date = moment(formDate, 'YYYY-MM-DD');
      const todate = moment(toDate, 'YYYY-MM-DD');
      if (todate.isBefore(date)) {
        this.shiftForm.get('endDate').setErrors({ endDateError: true });
      }
    }

    if (formDate) {
      const date = moment(moment(), 'YYYY-MM-DD');
      const todate = moment(formDate, 'YYYY-MM-DD');
      let duration = 0;
      duration = todate.diff(date, 'days');

      if (duration >= 0) {
        this.shiftForm.get('startDate').clearValidators();
      } else {
        this.shiftForm.get('startDate').setErrors({ startDateError: true });
      }
    }
  }

  getClientList() {
    this.apiService.get('client/list').subscribe((client: any) => {
      if (client.status === 200) {
        this.clientList = client.clients;
      }
    });
  }

  getStaffList() {
    this.apiService.get('auth/users').subscribe((staff: any) => {
      if (staff.status === 200) {
        this.staffList = staff.staffs;
      }
    });
  }

  clientChange(input) {
    this.getClientLocationList(input);
  }

  getClientLocationList(clientID) {
    this.apiService.get(`client/${clientID}/location`).subscribe((location: any) => {
      if (location.status === 200) {
        this.clientLocationList = location.location;

        if (this.setLocation) {
          if (this.locationId !== '') {
            this.shiftForm.get('location_id').setValue(this.locationId);
            this.locationChange(this.locationId);
          }
        }
      }
    });
  }

  locationChange(input) {
    const detail = this.clientLocationList.find((location) => location.id === +input);
    if (detail.locations.length > 0) {
      this.subLocation = detail.locations;
    }
  }

  get f() {
    return this.shiftForm.controls;
  }

  encodeURL(eventName: string) {
    return encodeURIComponent(eventName);
  }

  createShift() {
    const params = this.buildShiftParams();

    const { startDate, endDate, weekdays, startTime, endTime } = params;

    const shiftStart = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    let shiftEnd = moment(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');

    // Ensure shiftEnd is not before shiftStart
    if (shiftEnd.isBefore(shiftStart)) {
      shiftEnd = shiftEnd.add(1, 'days');
    }

    const isCurrentDay = moment(startDate).isSame(moment().startOf('day'));
    const isToday = isCurrentDay && moment(endDate).isSame(moment().startOf('day'));

    // Check if today is one of the weekdays and the shift is ongoing
    if (weekdays.includes(moment().isoWeekday()) && isToday) {
      const isShiftInPast = !(shiftStart.isAfter(moment()) || shiftEnd.isAfter(moment()));

      // Display an error message if the shift exceeds the current time
      if (isShiftInPast) {
        this.toastr.error('Your work time exceeds. Please add it as a past shift');
        return false;
      }
    }

    this.loading = true;
    this.apiService.postV2('shift', params).subscribe(
      (shift: any) => {
        this.loading = false;
        if (shift.status === 200) {
          this.closeModal();
          const doc = document.scrollingElement;
          const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
          const shiftData = shift.data && shift.data[0];
          console.log('shiftData :>> ', shift);
          if (shiftData) {
            let offset = Math.abs(
              moment(moment().startOf('isoWeek')).diff(moment(shiftData.startDate).startOf('isoWeek'), 'weeks'),
            );

            if (moment(moment().startOf('isoWeek')).isAfter(moment(shiftData.startDate).startOf('isoWeek'))) {
              offset = -offset;
            }
            if (!shiftData.event) {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/scheduler'], {
                  queryParams: {
                    isAll: false,
                    offset,
                    clientId: shiftData.client_id,
                    locationId: shiftData.location,
                    subLocationId: shiftData.subLocation ? shiftData.subLocation : '',
                    staffId: shiftData.staffId ? shiftData.staffId : '',
                    top,
                    fromDate: moment(shiftData.startDate).format('DD MMMM YYYY'),
                    toDate: moment(shiftData.startDate).add(1, 'weeks').format('DD MMMM YYYY'),
                  },
                }),
              );
            } else {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate([`/scheduler-event/${this.encodeURL(shiftData.event)}`], {
                  queryParams: {
                    eventName: shiftData.event,
                    isAll: false,
                    offset,
                    clientId: shiftData.client_id,
                    locationId: shiftData.location,
                    subLocationId: shiftData.subLocation ? shiftData.subLocation : '',
                    staffId: shiftData.staffId ? shiftData.staffId : '',
                    top,
                    fromDate: moment(shiftData.startDate).format('DD MMMM YYYY'),
                    toDate: moment(shiftData.startDate).add(1, 'weeks').format('DD MMMM YYYY'),
                  },
                }),
              );
            }
          }
        }
      },
      (err) => {
        this.errorToasterMessage(err);
      },
    );
  }

  errorToasterMessage(err) {
    this.loading = false;
    if (err.error.message) {
      this.toastr.error(err.error.message);
    }
  }

  validateEndHour(date: string, isPast: boolean) {
    const todayDate = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
    const currentTime = moment().format('HH:mm');
    const start = `${this.shiftForm.get('startHr').value}:${this.shiftForm.get('startMin').value}`;
    const end = `${this.shiftForm.get('endHr').value}:${this.shiftForm.get('endMin').value}`;
    const workDate = moment(date);
    if (todayDate.isSame(workDate)) {
      const startHour = moment(start, 'HH:mm');
      let endHour = moment(end, 'HH:mm');
      if (endHour.isBefore(startHour)) endHour = endHour.add(1, 'days');
      const startTimeCheck = moment(currentTime, 'HH:mm').isBefore(startHour);
      const endTimeCheck = moment(currentTime, 'HH:mm').isBefore(endHour);
      if (isPast) {
        if (startTimeCheck) {
          this.shiftForm.get('startHr').setErrors({ startHrerrors: true });
        }
        if (endTimeCheck || startTimeCheck) {
          this.shiftForm.get('endHr').setErrors({ endHrerrors: true });
        }
      } else if (moment(currentTime, 'HH:mm').isAfter(endHour)) {
        this.shiftForm.get('endHr').setErrors({ endHrerrors: true });
      }
    } else {
      this.shiftForm.get('endHr').setErrors(null);
      this.shiftForm.get('endHr').clearValidators();
      this.shiftForm.get('startHr').setErrors(null);
      this.shiftForm.get('startHr').clearValidators();
    }
  }

  public getFormattedDate(dateInput: Date): string {
    return moment(dateInput).format('YYYY-MM-DD');
  }

  createPastShift() {
    const params: any = {};
    params.client_id = this.shiftForm.get('client_id').value;
    params.staff_id = this.shiftForm.get('staff_id').value;
    params.location_id = this.shiftForm.get('location_id').value;
    if (this.shiftForm.get('sub_location_id').value) {
      params.sub_location_id = this.shiftForm.get('sub_location_id').value;
    }
    params.event = this.eventName ? this.eventName : this.shiftForm.get('event').value;
    params.work_date = this.getFormattedDate(this.shiftForm.get('work_date').value);
    params.startTime = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
    params.endTime = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
    this.loading = true;
    this.apiService.postV2('shift/past', params).subscribe(
      (shift: any) => {
        this.loading = false;
        if (shift.status === 200) {
          this.closeModal();
          this.apiService.reloadSchedule('success');
          this.toastr.success(shift.message);
        }
      },
      (err) => {
        this.errorToasterMessage(err);
      },
    );
  }

  onSubmit() {
    this.checkTimeandDate();
    this.submitted = true;
    if (this.isPast) {
      const date = this.getFormattedDate(this.shiftForm.get('work_date').value);
      this.validateEndHour(date, this.isPast);
      if (this.shiftForm.invalid) {
        return;
      }
      this.createPastShift();
    } else {
      // const date = this.getFormattedDate(this.shiftForm.get('endDate').value);
      // this.validateEndHour(date, this.isPast);
      if (this.shiftForm.invalid) {
        return;
      }
      this.createShift();
    }
  }

  onDateSelect(e) {
    if (e) {
      // if (e) this.eminDate = new Date(e);
    }
  }

  startWeek() {
    this.permanent = false;
    let fromDate = moment().startOf('isoWeek').format('YYYY, MM, DD');
    const toDate = moment().endOf('isoWeek').format('YYYY, MM, DD');
    if (moment(fromDate, 'YYYY, MM, DD').isBefore(moment(this.minDate))) {
      fromDate = moment(this.minDate).format('YYYY, MM, DD');
    }
    this.shiftForm.get('startDate').setValue(new Date(fromDate));
    this.shiftForm.get('endDate').setValue(new Date(toDate));
  }

  endWeek() {
    const today = moment();
    const toDate = today.endOf('isoWeek').format('YYYY, MM, DD');
    this.shiftForm.get('endDate').setValue(new Date(toDate));
  }

  setPermanent() {
    const shiftStartDate = this.shiftForm.get('startDate').value;
    if (this.shiftForm.get('permanent').value === 0) {
      this.shiftForm.get('permanent').setValue(1);
      let fromDate = moment().format('YYYY, MM, DD');
      if (shiftStartDate) {
        fromDate = moment(shiftStartDate).format('YYYY, MM, DD');
      }
      this.shiftForm.get('startDate').setValue(new Date(fromDate));
      const maxDate = moment().add(180, 'days').format('YYYY, MM, DD');
      this.shiftForm.get('endDate').setValue(new Date(maxDate));
    } else {
      this.shiftForm.get('permanent').setValue(0);
      this.shiftForm.get('startDate').setValue('');
      this.shiftForm.get('endDate').setValue('');
      const date = moment().format('YYYY, MM, DD');
      this.eminDate = new Date(date);
    }
  }

  closeModal() {
    this.apiService.clearList();
  }

  checkingDays(value, type) {
    const days = this.shiftForm.get('weekdays').value;
    const index = days.findIndex((day) => day === value.id);
    const index1 = this.seletedList.findIndex((day) => day === value.key);

    if (type) {
      if (index === -1) {
        days.push(value.id);
      }
      if (index1 === -1) {
        this.seletedList.push(value.key);
      }
    } else {
      if (index >= 0) {
        days.splice(index, 1);
      }
      if (index1 >= 0) {
        this.seletedList.splice(index1, 1);
      }
    }
    this.shiftForm.get('weekdays').setValue(days);
    this.dayNames = this.seletedList.toString();
  }

  clearStartDate() {
    this.permanent = false;

    this.shiftForm.get('startDate').setValue('');
    this.datepicker.toggle();
  }
  action() {
    this.datepicker1.toggle();
  }

  clearEndDate() {
    this.permanent = false;
    this.datepicker1.toggle();
  }

  createEndTimeAndMins(hr, min) {
    const date = new Date();
    const mindate = new Date();
    const interval = 1;
    const mininterval = 5;
    date.setHours(hr);
    date.setMinutes(min);
    mindate.setHours(hr);
    mindate.setMinutes(min);
    const mindata = [];
    let finalMindata = [];
    const stTime = this.shiftForm.get('startHr').value;
    const endTime = this.shiftForm.get('endHr').value;

    const data: any = [date.getHours() > 9 ? date.getHours() : '0' + date.getHours()];
    for (let i = 0; i < 23; i++) {
      date.setHours(date.getHours() + interval);
      const dateObj = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
      data.push(dateObj.toString());
    }

    for (let i = 0; i < 12; i++) {
      mindate.setMinutes(mindate.getMinutes() + mininterval);
      if (i != 11) {
        mindata.push(mindate.getMinutes());
      } else {
        mindata.push(mindata[mindata.length - 1] + 4);
      }
    }
    if (stTime === endTime) {
      mindata.forEach((item) => {
        item = item > 9 ? item : '0' + item;
        finalMindata.push(item);
      });
    } else {
      finalMindata = this.minitsList;
    }

    this.endHourList = data;
    this.endMinitsList = finalMindata;
  }

  selectStartTime(value) {
    const minvalue = this.shiftForm.get('startMin').value;
    this.endHourList = [];
    this.createEndTimeAndMins(value, minvalue);
  }

  selectStartMin(value) {
    const stTime = this.shiftForm.get('startHr').value;
    this.endMinitsList = [];
    this.createEndTimeAndMins(stTime, value);
  }

  selectendHr(hr) {
    const stMin = this.shiftForm.get('startMin').value;
  }

  isValueCheck(e) {
    let value = e.target.value;
    if (e.target.value.length === 0) {
      value = e.key;
    } else {
      value = value + '' + e.key;
    }
    return value <= 50;
  }

  pastShift() {
    const date = moment().format('YYYY, MM, DD');
    this.isPast = !this.isPast;
    this.minDate = new Date(date);
    this.eminDate = new Date(date);

    if (this.isPast) {
      this.shiftHeaderTitle = 'Add Past Shift';
      this.apiService.headerModel.emit(this.shiftHeaderTitle);
      this.buttonLabel = 'Add Shift';
      this.shiftForm.get('number_of_staffs').clearValidators();
      this.shiftForm.get('weekdays').clearValidators();
      this.shiftForm.get('startDate').clearValidators();
      this.shiftForm.get('endDate').clearValidators();
      this.shiftForm.get('staff_id').setValidators([Validators.required]);
      this.shiftForm.get('work_date').setValidators([Validators.required]);
      this.shiftForm.get('number_of_staffs').updateValueAndValidity();
      this.shiftForm.get('weekdays').updateValueAndValidity();
      this.shiftForm.get('startDate').updateValueAndValidity();
      this.shiftForm.get('endDate').updateValueAndValidity();
      this.shiftForm.get('staff_id').updateValueAndValidity();
      this.shiftForm.get('work_date').updateValueAndValidity();
    }
    if (!this.isPast) {
      this.shiftHeaderTitle = 'Add Shift';
      this.apiService.headerModel.emit(this.shiftHeaderTitle);
      this.buttonLabel = 'Add Past Shift';
      this.shiftForm.get('staff_id').clearValidators();
      this.shiftForm.get('work_date').clearValidators();
      this.shiftForm.get('number_of_staffs').setValidators([Validators.required]);
      this.shiftForm.get('weekdays').setValidators([Validators.required]);
      this.shiftForm.get('startDate').setValidators([Validators.required]);
      this.shiftForm.get('endDate').setValidators([Validators.required]);
      this.shiftForm.get('staff_id').updateValueAndValidity();
      this.shiftForm.get('work_date').updateValueAndValidity();
      this.shiftForm.get('number_of_staffs').updateValueAndValidity();
      this.shiftForm.get('weekdays').updateValueAndValidity();
      this.shiftForm.get('startDate').updateValueAndValidity();
      this.shiftForm.get('endDate').updateValueAndValidity();
    }
  }
}
