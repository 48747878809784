import { OnInit } from '@angular/core';
import { ApiService } from '../services';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { days } from '../constants/defaultValues';
var AddShiftComponent = /** @class */ (function () {
    function AddShiftComponent(apiService, toastr, route, formBuilder, router) {
        this.apiService = apiService;
        this.toastr = toastr;
        this.route = route;
        this.formBuilder = formBuilder;
        this.router = router;
        this.bsValue = new Date();
        this.clientList = [];
        this.clientLocationList = [];
        this.subLocation = [];
        this.weekDays = days;
        this.interval = 5;
        this.times = [];
        this.tt = 0;
        this.timeList = [];
        this.hourTimeList = [];
        this.miniuteTimeList = [];
        this.hourList = [];
        this.endHourList = [];
        this.minitsList = [];
        this.endMinitsList = [];
        this.submitted = false;
        this.seletedList = [];
        this.dayNames = '';
        this.staffList = [];
        this.loading = false;
        this.top = 0;
        this.showHour = '-1';
        this.isPast = false;
        this.clientId = '';
        this.locationId = '';
        this.subLocationId = '';
        this.eventName = '';
        this.buttonLabel = 'Add Past Shift';
        this.setLocation = false;
        this.scrollx = 0;
        this.scrolly = 0;
        this.domainInfo = {};
        this.maxEnddate = new Date(moment().add(180, 'days').format('YYYY-MM-DD'));
        this.permanent = false;
        this.domainInfo = JSON.parse(localStorage.getItem('domainInfo')) || {};
        var date = moment().format('YYYY, MM, DD');
        var maxDate = moment().add(180, 'days').format('YYYY, MM, DD');
        this.minDate = new Date(date);
        this.maxDate = new Date(maxDate);
        this.eminDate = new Date(date);
        var _loop_1 = function (i) {
            var hh = Math.floor(this_1.tt / 60);
            var index = this_1.hourList.findIndex(function (hour) { return hour === ('0' + (hh % 24)).slice(-2); });
            if (index === -1) {
                this_1.hourList.push(('0' + (hh % 24)).slice(-2));
                this_1.endHourList.push(('0' + (hh % 24)).slice(-2));
                this_1.hourTimeList.push(('0' + (hh % 24)).slice(-2));
            }
            var mm = this_1.tt % 60;
            var indexm = this_1.minitsList.findIndex(function (minits) { return minits === ('0' + mm).slice(-2); });
            if (indexm === -1) {
                this_1.minitsList.push(('0' + mm).slice(-2));
                this_1.endMinitsList.push(('0' + mm).slice(-2));
                this_1.miniuteTimeList.push(('0' + mm).slice(-2));
            }
            this_1.tt = this_1.tt + this_1.interval;
        };
        var this_1 = this;
        for (var i = 0; this.tt < 24 * 60; i++) {
            _loop_1(i);
        }
        this.minitsList.push('59');
        this.endMinitsList.push('59');
        this.miniuteTimeList.push('59');
    }
    AddShiftComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventName = this.route.snapshot.queryParamMap.get('eventName')
            ? this.route.snapshot.queryParamMap.get('eventName')
            : '';
        this.formInitialize();
        this.getClientList();
        this.getStaffList();
        this.clientId = this.route.snapshot.queryParamMap.get('clientId')
            ? parseInt(this.route.snapshot.queryParamMap.get('clientId'))
            : '';
        this.locationId = this.route.snapshot.queryParamMap.get('locationId')
            ? parseInt(this.route.snapshot.queryParamMap.get('locationId'))
            : '';
        this.subLocationId = this.route.snapshot.queryParamMap.get('subLocationId')
            ? parseInt(this.route.snapshot.queryParamMap.get('subLocationId'))
            : '';
        if (this.clientId !== '') {
            this.shiftForm.get('client_id').setValue(this.clientId);
            this.setLocation = true;
            this.clientChange(this.clientId);
        }
        this.apiService.closeClientModel.subscribe(function (data) {
            if (data) {
                _this.getClientList();
                _this.getStaffList();
            }
        });
    };
    AddShiftComponent.prototype.formInitialize = function () {
        var _this = this;
        this.shiftForm = this.formBuilder.group({
            client_id: ['', Validators.required],
            location_id: ['', Validators.required],
            number_of_staffs: ['', [Validators.required]],
            weekdays: [[], [Validators.required]],
            startDate: ['', [Validators.required]],
            endDate: ['', [Validators.required]],
            startHr: ['00', [Validators.required]],
            endHr: ['00', [Validators.required]],
            startMin: ['00', [Validators.required]],
            endMin: ['00', [Validators.required]],
            sub_location_id: [''],
            event: new FormControl({ value: this.eventName, disabled: this.eventName !== '' }),
            code: [''],
            additional_notes: [''],
            permanent: [0],
            staff_id: [''],
            work_date: [''],
        });
        this.shiftForm.get('startDate').valueChanges.subscribe(function () {
            var fromDate = _this.shiftForm.get('startDate').value;
            _this.eminDate = fromDate;
            _this.shiftForm.get('endDate').setValue('');
            _this.checkDate();
        });
        this.shiftForm.get('endDate').valueChanges.subscribe(function () {
            _this.checkDate();
        });
    };
    AddShiftComponent.prototype.buildShiftParams = function () {
        var shiftParams = {
            client_id: this.shiftForm.get('client_id').value,
            location_id: this.shiftForm.get('location_id').value,
            number_of_staffs: this.shiftForm.get('number_of_staffs').value,
            weekdays: this.shiftForm.get('weekdays').value.map(Number),
            startDate: this.getFormattedDate(this.shiftForm.get('startDate').value),
            endDate: this.getFormattedDate(this.shiftForm.get('endDate').value),
            event: this.eventName ? this.eventName : this.shiftForm.get('event').value,
            code: this.shiftForm.get('code').value,
            additional_notes: this.shiftForm.get('additional_notes').value,
            permanent: this.shiftForm.get('permanent').value,
            startTime: this.shiftForm.get('startHr').value + ":" + this.shiftForm.get('startMin').value + ":00",
            endTime: this.shiftForm.get('endHr').value + ":" + this.shiftForm.get('endMin').value + ":00",
        };
        if (this.shiftForm.get('sub_location_id').value) {
            shiftParams.sub_location_id = this.shiftForm.get('sub_location_id').value;
        }
        return shiftParams;
    };
    AddShiftComponent.prototype.buildShiftParamsCamelCase = function () {
        var shiftParams = {
            clientId: +this.shiftForm.get('client_id').value,
            locationId: +this.shiftForm.get('location_id').value,
            staffCount: +this.shiftForm.get('number_of_staffs').value,
            days: this.shiftForm.get('weekdays').value.map(Number),
            startDate: this.getFormattedDate(this.shiftForm.get('startDate').value),
            endDate: this.getFormattedDate(this.shiftForm.get('endDate').value),
            event: this.eventName ? this.eventName : this.shiftForm.get('event').value,
            code: this.shiftForm.get('code').value,
            additionalNotes: this.shiftForm.get('additional_notes').value,
            isPermanent: this.shiftForm.get('permanent').value === 1,
            startTime: this.shiftForm.get('startHr').value + ":" + this.shiftForm.get('startMin').value + ":00",
            endTime: this.shiftForm.get('endHr').value + ":" + this.shiftForm.get('endMin').value + ":00",
        };
        if (this.shiftForm.get('sub_location_id').value) {
            shiftParams.subLocationId = +this.shiftForm.get('sub_location_id').value;
        }
        return shiftParams;
    };
    AddShiftComponent.prototype.checkTimeandDate = function () {
        this.showHour = '-1';
        var fromTime = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
        var toTime = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
        this.shiftForm.get('startHr').setErrors(null);
        this.shiftForm.get('startHr').clearValidators();
        if (fromTime === toTime) {
            this.shiftForm.get('endHr').setErrors({ toTimeError: true });
        }
        else {
            this.shiftForm.get('endHr').setErrors(null);
            this.shiftForm.get('endHr').clearValidators();
        }
    };
    AddShiftComponent.prototype.checkDate = function () {
        var formDate = this.shiftForm.get('startDate').value;
        var toDate = this.shiftForm.get('endDate').value;
        if (formDate)
            formDate = moment(formDate).add(1, 'days');
        if (toDate)
            toDate = moment(toDate).add(1, 'days');
        if (formDate && toDate) {
            var date = moment(formDate, 'YYYY-MM-DD');
            var todate = moment(toDate, 'YYYY-MM-DD');
            if (todate.isBefore(date)) {
                this.shiftForm.get('endDate').setErrors({ endDateError: true });
            }
        }
        if (formDate) {
            var date = moment(moment(), 'YYYY-MM-DD');
            var todate = moment(formDate, 'YYYY-MM-DD');
            var duration = 0;
            duration = todate.diff(date, 'days');
            if (duration >= 0) {
                this.shiftForm.get('startDate').clearValidators();
            }
            else {
                this.shiftForm.get('startDate').setErrors({ startDateError: true });
            }
        }
    };
    AddShiftComponent.prototype.getClientList = function () {
        var _this = this;
        this.apiService.get('client/list').subscribe(function (client) {
            if (client.status === 200) {
                _this.clientList = client.clients;
            }
        });
    };
    AddShiftComponent.prototype.getStaffList = function () {
        var _this = this;
        this.apiService.get('auth/users').subscribe(function (staff) {
            if (staff.status === 200) {
                _this.staffList = staff.staffs;
            }
        });
    };
    AddShiftComponent.prototype.clientChange = function (input) {
        this.getClientLocationList(input);
    };
    AddShiftComponent.prototype.getClientLocationList = function (clientID) {
        var _this = this;
        this.apiService.get("client/" + clientID + "/location").subscribe(function (location) {
            if (location.status === 200) {
                _this.clientLocationList = location.location;
                if (_this.setLocation) {
                    if (_this.locationId !== '') {
                        _this.shiftForm.get('location_id').setValue(_this.locationId);
                        _this.locationChange(_this.locationId);
                    }
                }
            }
        });
    };
    AddShiftComponent.prototype.locationChange = function (input) {
        var detail = this.clientLocationList.find(function (location) { return location.id === +input; });
        if (detail.locations.length > 0) {
            this.subLocation = detail.locations;
        }
    };
    Object.defineProperty(AddShiftComponent.prototype, "f", {
        get: function () {
            return this.shiftForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddShiftComponent.prototype.encodeURL = function (eventName) {
        return encodeURIComponent(eventName);
    };
    AddShiftComponent.prototype.createShift = function () {
        var _this = this;
        var params = this.buildShiftParams();
        var startDate = params.startDate, endDate = params.endDate, weekdays = params.weekdays, startTime = params.startTime, endTime = params.endTime;
        var shiftStart = moment(startDate + " " + startTime, 'YYYY-MM-DD HH:mm:ss');
        var shiftEnd = moment(endDate + " " + endTime, 'YYYY-MM-DD HH:mm:ss');
        // Ensure shiftEnd is not before shiftStart
        if (shiftEnd.isBefore(shiftStart)) {
            shiftEnd = shiftEnd.add(1, 'days');
        }
        var isCurrentDay = moment(startDate).isSame(moment().startOf('day'));
        var isToday = isCurrentDay && moment(endDate).isSame(moment().startOf('day'));
        // Check if today is one of the weekdays and the shift is ongoing
        if (weekdays.includes(moment().isoWeekday()) && isToday) {
            var isShiftInPast = !(shiftStart.isAfter(moment()) || shiftEnd.isAfter(moment()));
            // Display an error message if the shift exceeds the current time
            if (isShiftInPast) {
                this.toastr.error('Your work time exceeds. Please add it as a past shift');
                return false;
            }
        }
        this.loading = true;
        this.apiService.postV2('shift', params).subscribe(function (shift) {
            _this.loading = false;
            if (shift.status === 200) {
                _this.closeModal();
                var doc = document.scrollingElement;
                var top_1 = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
                var shiftData_1 = shift.data && shift.data[0];
                console.log('shiftData :>> ', shift);
                if (shiftData_1) {
                    var offset_1 = Math.abs(moment(moment().startOf('isoWeek')).diff(moment(shiftData_1.startDate).startOf('isoWeek'), 'weeks'));
                    if (moment(moment().startOf('isoWeek')).isAfter(moment(shiftData_1.startDate).startOf('isoWeek'))) {
                        offset_1 = -offset_1;
                    }
                    if (!shiftData_1.event) {
                        _this.router.navigateByUrl('/', { skipLocationChange: true }).then(function () {
                            return _this.router.navigate(['/scheduler'], {
                                queryParams: {
                                    isAll: false,
                                    offset: offset_1,
                                    clientId: shiftData_1.client_id,
                                    locationId: shiftData_1.location,
                                    subLocationId: shiftData_1.subLocation ? shiftData_1.subLocation : '',
                                    staffId: shiftData_1.staffId ? shiftData_1.staffId : '',
                                    top: top_1,
                                    fromDate: moment(shiftData_1.startDate).format('DD MMMM YYYY'),
                                    toDate: moment(shiftData_1.startDate).add(1, 'weeks').format('DD MMMM YYYY'),
                                },
                            });
                        });
                    }
                    else {
                        _this.router.navigateByUrl('/', { skipLocationChange: true }).then(function () {
                            return _this.router.navigate(["/scheduler-event/" + _this.encodeURL(shiftData_1.event)], {
                                queryParams: {
                                    eventName: shiftData_1.event,
                                    isAll: false,
                                    offset: offset_1,
                                    clientId: shiftData_1.client_id,
                                    locationId: shiftData_1.location,
                                    subLocationId: shiftData_1.subLocation ? shiftData_1.subLocation : '',
                                    staffId: shiftData_1.staffId ? shiftData_1.staffId : '',
                                    top: top_1,
                                    fromDate: moment(shiftData_1.startDate).format('DD MMMM YYYY'),
                                    toDate: moment(shiftData_1.startDate).add(1, 'weeks').format('DD MMMM YYYY'),
                                },
                            });
                        });
                    }
                }
            }
        }, function (err) {
            _this.errorToasterMessage(err);
        });
    };
    AddShiftComponent.prototype.errorToasterMessage = function (err) {
        this.loading = false;
        if (err.error.message) {
            this.toastr.error(err.error.message);
        }
    };
    AddShiftComponent.prototype.validateEndHour = function (date, isPast) {
        var todayDate = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
        var currentTime = moment().format('HH:mm');
        var start = this.shiftForm.get('startHr').value + ":" + this.shiftForm.get('startMin').value;
        var end = this.shiftForm.get('endHr').value + ":" + this.shiftForm.get('endMin').value;
        var workDate = moment(date);
        if (todayDate.isSame(workDate)) {
            var startHour = moment(start, 'HH:mm');
            var endHour = moment(end, 'HH:mm');
            if (endHour.isBefore(startHour))
                endHour = endHour.add(1, 'days');
            var startTimeCheck = moment(currentTime, 'HH:mm').isBefore(startHour);
            var endTimeCheck = moment(currentTime, 'HH:mm').isBefore(endHour);
            if (isPast) {
                if (startTimeCheck) {
                    this.shiftForm.get('startHr').setErrors({ startHrerrors: true });
                }
                if (endTimeCheck || startTimeCheck) {
                    this.shiftForm.get('endHr').setErrors({ endHrerrors: true });
                }
            }
            else if (moment(currentTime, 'HH:mm').isAfter(endHour)) {
                this.shiftForm.get('endHr').setErrors({ endHrerrors: true });
            }
        }
        else {
            this.shiftForm.get('endHr').setErrors(null);
            this.shiftForm.get('endHr').clearValidators();
            this.shiftForm.get('startHr').setErrors(null);
            this.shiftForm.get('startHr').clearValidators();
        }
    };
    AddShiftComponent.prototype.getFormattedDate = function (dateInput) {
        return moment(dateInput).format('YYYY-MM-DD');
    };
    AddShiftComponent.prototype.createPastShift = function () {
        var _this = this;
        var params = {};
        params.client_id = this.shiftForm.get('client_id').value;
        params.staff_id = this.shiftForm.get('staff_id').value;
        params.location_id = this.shiftForm.get('location_id').value;
        if (this.shiftForm.get('sub_location_id').value) {
            params.sub_location_id = this.shiftForm.get('sub_location_id').value;
        }
        params.event = this.eventName ? this.eventName : this.shiftForm.get('event').value;
        params.work_date = this.getFormattedDate(this.shiftForm.get('work_date').value);
        params.startTime = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
        params.endTime = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
        this.loading = true;
        this.apiService.postV2('shift/past', params).subscribe(function (shift) {
            _this.loading = false;
            if (shift.status === 200) {
                _this.closeModal();
                _this.apiService.reloadSchedule('success');
                _this.toastr.success(shift.message);
            }
        }, function (err) {
            _this.errorToasterMessage(err);
        });
    };
    AddShiftComponent.prototype.onSubmit = function () {
        this.checkTimeandDate();
        this.submitted = true;
        if (this.isPast) {
            var date = this.getFormattedDate(this.shiftForm.get('work_date').value);
            this.validateEndHour(date, this.isPast);
            if (this.shiftForm.invalid) {
                return;
            }
            this.createPastShift();
        }
        else {
            // const date = this.getFormattedDate(this.shiftForm.get('endDate').value);
            // this.validateEndHour(date, this.isPast);
            if (this.shiftForm.invalid) {
                return;
            }
            this.createShift();
        }
    };
    AddShiftComponent.prototype.onDateSelect = function (e) {
        if (e) {
            // if (e) this.eminDate = new Date(e);
        }
    };
    AddShiftComponent.prototype.startWeek = function () {
        this.permanent = false;
        var fromDate = moment().startOf('isoWeek').format('YYYY, MM, DD');
        var toDate = moment().endOf('isoWeek').format('YYYY, MM, DD');
        if (moment(fromDate, 'YYYY, MM, DD').isBefore(moment(this.minDate))) {
            fromDate = moment(this.minDate).format('YYYY, MM, DD');
        }
        this.shiftForm.get('startDate').setValue(new Date(fromDate));
        this.shiftForm.get('endDate').setValue(new Date(toDate));
    };
    AddShiftComponent.prototype.endWeek = function () {
        var today = moment();
        var toDate = today.endOf('isoWeek').format('YYYY, MM, DD');
        this.shiftForm.get('endDate').setValue(new Date(toDate));
    };
    AddShiftComponent.prototype.setPermanent = function () {
        var shiftStartDate = this.shiftForm.get('startDate').value;
        if (this.shiftForm.get('permanent').value === 0) {
            this.shiftForm.get('permanent').setValue(1);
            var fromDate = moment().format('YYYY, MM, DD');
            if (shiftStartDate) {
                fromDate = moment(shiftStartDate).format('YYYY, MM, DD');
            }
            this.shiftForm.get('startDate').setValue(new Date(fromDate));
            var maxDate = moment().add(180, 'days').format('YYYY, MM, DD');
            this.shiftForm.get('endDate').setValue(new Date(maxDate));
        }
        else {
            this.shiftForm.get('permanent').setValue(0);
            this.shiftForm.get('startDate').setValue('');
            this.shiftForm.get('endDate').setValue('');
            var date = moment().format('YYYY, MM, DD');
            this.eminDate = new Date(date);
        }
    };
    AddShiftComponent.prototype.closeModal = function () {
        this.apiService.clearList();
    };
    AddShiftComponent.prototype.checkingDays = function (value, type) {
        var days = this.shiftForm.get('weekdays').value;
        var index = days.findIndex(function (day) { return day === value.id; });
        var index1 = this.seletedList.findIndex(function (day) { return day === value.key; });
        if (type) {
            if (index === -1) {
                days.push(value.id);
            }
            if (index1 === -1) {
                this.seletedList.push(value.key);
            }
        }
        else {
            if (index >= 0) {
                days.splice(index, 1);
            }
            if (index1 >= 0) {
                this.seletedList.splice(index1, 1);
            }
        }
        this.shiftForm.get('weekdays').setValue(days);
        this.dayNames = this.seletedList.toString();
    };
    AddShiftComponent.prototype.clearStartDate = function () {
        this.permanent = false;
        this.shiftForm.get('startDate').setValue('');
        this.datepicker.toggle();
    };
    AddShiftComponent.prototype.action = function () {
        this.datepicker1.toggle();
    };
    AddShiftComponent.prototype.clearEndDate = function () {
        this.permanent = false;
        this.datepicker1.toggle();
    };
    AddShiftComponent.prototype.createEndTimeAndMins = function (hr, min) {
        var date = new Date();
        var mindate = new Date();
        var interval = 1;
        var mininterval = 5;
        date.setHours(hr);
        date.setMinutes(min);
        mindate.setHours(hr);
        mindate.setMinutes(min);
        var mindata = [];
        var finalMindata = [];
        var stTime = this.shiftForm.get('startHr').value;
        var endTime = this.shiftForm.get('endHr').value;
        var data = [date.getHours() > 9 ? date.getHours() : '0' + date.getHours()];
        for (var i = 0; i < 23; i++) {
            date.setHours(date.getHours() + interval);
            var dateObj = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
            data.push(dateObj.toString());
        }
        for (var i = 0; i < 12; i++) {
            mindate.setMinutes(mindate.getMinutes() + mininterval);
            if (i != 11) {
                mindata.push(mindate.getMinutes());
            }
            else {
                mindata.push(mindata[mindata.length - 1] + 4);
            }
        }
        if (stTime === endTime) {
            mindata.forEach(function (item) {
                item = item > 9 ? item : '0' + item;
                finalMindata.push(item);
            });
        }
        else {
            finalMindata = this.minitsList;
        }
        this.endHourList = data;
        this.endMinitsList = finalMindata;
    };
    AddShiftComponent.prototype.selectStartTime = function (value) {
        var minvalue = this.shiftForm.get('startMin').value;
        this.endHourList = [];
        this.createEndTimeAndMins(value, minvalue);
    };
    AddShiftComponent.prototype.selectStartMin = function (value) {
        var stTime = this.shiftForm.get('startHr').value;
        this.endMinitsList = [];
        this.createEndTimeAndMins(stTime, value);
    };
    AddShiftComponent.prototype.selectendHr = function (hr) {
        var stMin = this.shiftForm.get('startMin').value;
    };
    AddShiftComponent.prototype.isValueCheck = function (e) {
        var value = e.target.value;
        if (e.target.value.length === 0) {
            value = e.key;
        }
        else {
            value = value + '' + e.key;
        }
        return value <= 50;
    };
    AddShiftComponent.prototype.pastShift = function () {
        var date = moment().format('YYYY, MM, DD');
        this.isPast = !this.isPast;
        this.minDate = new Date(date);
        this.eminDate = new Date(date);
        if (this.isPast) {
            this.shiftHeaderTitle = 'Add Past Shift';
            this.apiService.headerModel.emit(this.shiftHeaderTitle);
            this.buttonLabel = 'Add Shift';
            this.shiftForm.get('number_of_staffs').clearValidators();
            this.shiftForm.get('weekdays').clearValidators();
            this.shiftForm.get('startDate').clearValidators();
            this.shiftForm.get('endDate').clearValidators();
            this.shiftForm.get('staff_id').setValidators([Validators.required]);
            this.shiftForm.get('work_date').setValidators([Validators.required]);
            this.shiftForm.get('number_of_staffs').updateValueAndValidity();
            this.shiftForm.get('weekdays').updateValueAndValidity();
            this.shiftForm.get('startDate').updateValueAndValidity();
            this.shiftForm.get('endDate').updateValueAndValidity();
            this.shiftForm.get('staff_id').updateValueAndValidity();
            this.shiftForm.get('work_date').updateValueAndValidity();
        }
        if (!this.isPast) {
            this.shiftHeaderTitle = 'Add Shift';
            this.apiService.headerModel.emit(this.shiftHeaderTitle);
            this.buttonLabel = 'Add Past Shift';
            this.shiftForm.get('staff_id').clearValidators();
            this.shiftForm.get('work_date').clearValidators();
            this.shiftForm.get('number_of_staffs').setValidators([Validators.required]);
            this.shiftForm.get('weekdays').setValidators([Validators.required]);
            this.shiftForm.get('startDate').setValidators([Validators.required]);
            this.shiftForm.get('endDate').setValidators([Validators.required]);
            this.shiftForm.get('staff_id').updateValueAndValidity();
            this.shiftForm.get('work_date').updateValueAndValidity();
            this.shiftForm.get('number_of_staffs').updateValueAndValidity();
            this.shiftForm.get('weekdays').updateValueAndValidity();
            this.shiftForm.get('startDate').updateValueAndValidity();
            this.shiftForm.get('endDate').updateValueAndValidity();
        }
    };
    return AddShiftComponent;
}());
export { AddShiftComponent };
